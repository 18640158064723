@font-face {
  font-family: speedee;
  src: local("speedee"), url(./fonts/Speedee_W_Rg.woff2) format("woff2"),
    url(./fonts/Speedee_W_Rg.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  display: fallback;
}

@font-face {
  font-family: speedee-bold;
  src: local("speedee-bold"), url(./fonts/Speedee_W_Bd.woff2) format("woff2"),
    url(./fonts/Speedee_W_Bd.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  display: fallback;
}

@font-face {
  font-family: speedee-light;
  src: local("speedee-light"), url(./fonts/Speedee_W_Lt.woff2) format("woff2"),
    url(./fonts/Speedee_W_Lt.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  display: fallback;
}

@font-face {
  font-family: speedee-condensed;
  src: local("speedee-condensed"),
    url(./fonts/Speedee_Condense_rg.woff2) format("woff2"),
    url(./fonts/Speedee_Condense_rg.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  display: fallback;
}

@font-face {
  font-family: speedee-condensed-bold;
  src: local("speedee-condensed-bold"),
    url(./fonts/Speedee_Condense_bold.woff2) format("woff2"),
    url(./fonts/Speedee_Condense_bold.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  display: fallback;
}

@font-face {
  font-family: speedee-condensed-light;
  src: local("speedee-condensed-light"),
    url(./fonts/Speedee_Condense_lt.woff2) format("woff2"),
    url(./fonts/Speedee_Condense_lt.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  display: fallback;
}
